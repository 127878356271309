import styled from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';
import type { ArticleGroupArticleFieldsFragment, ArticleInFeedFieldsFragment } from '@news/gql';

import { LazyImage } from 'components/LazyImage';
import { theme } from 'styles/theme';

interface Props {
  article: ArticleInFeedFieldsFragment | ArticleGroupArticleFieldsFragment;
  feedSlug?: string;
}
const OpinionPiece: React.FC<Props> = ({ article }) => {
  const byline = article.byline[0];

  if (!byline || !byline.bylineImage) return null;

  return (
    <OpinionPieceWrapper>
      <BylineImage src={byline.bylineImage} alt="" />
      <ContentWrapper>
        <Author>
          {byline.name}
          <Span>Analys</Span>
        </Author>
        <Title>{article.title}</Title>
      </ContentWrapper>
    </OpinionPieceWrapper>
  );
};

const OpinionPieceWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  background: ${colors.white[100]};
  border-left: 4px solid ${colors.yellow.bright};
  padding: 12px 16px;
  gap: 8px;
`;

const Author = styled.p`
  font-size: '12px';
  font-weight: 400;
  line-height: 1.35;
  color: ${colors.red.tv4Light};
  margin: 0;
  ${theme.mq.tablet} {
    font-size: '16px';
    font-weight: 500;
    line-height: 1.6;
    color: ${colors.red.tv4Light};
  }
`;

const BylineImage = styled(LazyImage)`
  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;
  flex-shrink: 0;
  border-radius: 50%;
  object-fit: cover;

  ${theme.mq.tablet} {
    width: 72px;
    height: 72px;
    min-width: 72px;
    min-height: 72px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  flex: 1;
`;

const Span = styled.span`
  color: ${colors.black[100]};
  margin-left: 10px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.2px;
  text-decoration: none;
`;

const Title = styled.p`
  font-size: 24px;
  font-weight: 500;
  font-style: italic;
  margin: 0;
  text-decoration: none;
  line-height: 1.2; /* Added line height for better readability */

  ${theme.mq.tablet} {
    font-size: 32px;
    font-style: italic;
    font-weight: 500;
    line-height: 1.3;
  }
`;

export default OpinionPiece;
