import React from 'react';
import styled from 'styled-components';

import type { ArticleGroupFieldsFragment } from '@news/gql';

import { squareCardBorderRadius } from 'styles/mixins/borderRadius';
import { theme } from 'styles/theme';

import { ArticleGroupArticleLink } from './ArticleGroupArticleLink';
import { ArticleGroupBlock } from './ArticleGroupBlock';

interface ArticleGroupProps {
  articleGroup: ArticleGroupFieldsFragment;
  index: number;
}

export const ArticleGroup: React.FC<ArticleGroupProps> = ({ articleGroup }) => {
  return (
    <ArticleGroupWrapper>
      {articleGroup.content.map((content) => {
        switch (content?.__typename) {
          case 'ArticleGroupArticle':
            return (
              <ArticleGroupArticleLink key={content.id} article={content} isEmphasized={articleGroup.isEmphasized} />
            );

          case 'ArticleGroupBlock':
            return <ArticleGroupBlock key={content.id} block={content} isEmphasized={articleGroup.isEmphasized} />;

          default:
            return null;
        }
      })}
    </ArticleGroupWrapper>
  );
};

const ArticleGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;

  ${theme.mq.tablet} {
    ${squareCardBorderRadius}
  }
`;
