import { colors } from './colors';

export type Variant =
  | 'page-header'
  | 'title1'
  | 'title2'
  | 'title3'
  | 'title3-strong'
  | 'title4'
  | 'body1'
  | 'body1-strong'
  | 'body2'
  | 'body2-strong'
  | 'body3'
  | 'body3-strong'
  | 'small-button-text'
  | 'smaller-button-text'
  | 'nav-link-text'
  | 'caption'
  | 'CTA'
  | 'CTA-large'
  | 'label'
  | 'live-label'
  | 'live-title'
  | 'metadata'
  | 'metadata-small'
  | 'tab-active-text'
  | 'tab-inactive-text'
  | 'upsell-title'
  | 'upsell-subtitle'
  | 'emphasized-title'
  | 'byline-name';

type Unit = `${number}px`;

type Typography = {
  fontSize: Unit;
  fontWeight: number;
  lineHeight: number;
  letterSpacing?: Unit;
  textDecorationLine?: string;
  textTransform?: string;
  color?: string;
};

export const mobile: Record<Variant, Typography> = {
  'page-header': {
    fontSize: '28px',
    fontWeight: 500,
    lineHeight: 1.3,
  },
  title1: {
    fontSize: '22px',
    fontWeight: 500,
    lineHeight: 1.25,
  },
  title2: {
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: 1.4,
    letterSpacing: '0.2px',
  },
  'title3-strong': {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: 1.2,
  },
  title3: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: 1.2,
  },
  title4: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 1.3,
  },
  body1: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: 1.6,
  },
  'body1-strong': {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: 1.6,
  },
  body2: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 1.4,
  },
  'body2-strong': {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: 1.5,
  },
  body3: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: 1.4,
  },
  'body3-strong': {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: 1.4,
  },
  'small-button-text': {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: '0.2px',
  },
  'smaller-button-text': {
    fontSize: '11px',
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: '0.2px',
  },
  'nav-link-text': {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: '0.2px',
    textDecorationLine: 'underline',
  },
  caption: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: 1.2,
  },
  CTA: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: 1,
  },
  'CTA-large': {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: 1,
  },
  label: {
    fontSize: '10px',
    fontWeight: 500,
    lineHeight: 1,
    textTransform: 'uppercase',
  },
  'live-label': {
    fontSize: '16px',
    fontWeight: 500,
    // The line height should account for the difference with the live-title
    lineHeight: 1.4,
    letterSpacing: '-0.2px',
    textTransform: 'uppercase',
    color: colors.red.tv4,
  },
  'live-title': {
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: 1.2,
  },
  metadata: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: 1.2,
  },
  'metadata-small': {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: 1.2,
  },
  'tab-active-text': {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: 1,
  },
  'tab-inactive-text': {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 1,
  },
  'upsell-title': {
    fontSize: '27px',
    fontWeight: 500,
    lineHeight: 1.15,
  },
  'upsell-subtitle': {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: 1.35,
  },
  'emphasized-title': {
    fontSize: '30px',
    fontWeight: 500,
    lineHeight: 1.2,
  },
  'byline-name': {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: 1.35,
    color: colors.red.tv4Light,
  },
};

export const desktop: Record<Variant, Typography> = {
  'page-header': {
    fontSize: '40px',
    fontWeight: 500,
    lineHeight: 1,
  },
  title1: {
    fontSize: '32px',
    fontWeight: 500,
    lineHeight: 1.3,
  },
  title2: {
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: 1.4,
    letterSpacing: '0.2px',
  },
  'title3-strong': {
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: 1.4,
    letterSpacing: '0.2px',
  },
  title3: {
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: 1.4,
    letterSpacing: '0.2px',
  },
  title4: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 1.2,
    letterSpacing: '0.2px',
  },
  body1: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: 1.5,
  },
  'body1-strong': {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: 1.6,
  },
  body2: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 1.5,
  },
  'body2-strong': {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: 1.5,
  },
  body3: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: 1.4,
  },
  'body3-strong': {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: 1.4,
  },
  'small-button-text': {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 1,
  },
  'smaller-button-text': {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: 1,
  },
  'nav-link-text': {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 1,
    textDecorationLine: 'underline',
  },
  caption: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: 1.2,
  },
  CTA: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: 1,
  },
  'CTA-large': {
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: 1,
  },
  label: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: 1,
    textTransform: 'uppercase',
  },
  'live-label': {
    fontSize: '29px',
    fontWeight: 500,
    lineHeight: 1,
    letterSpacing: '-0.2px',
    textTransform: 'uppercase',
    color: colors.red.tv4,
  },
  'live-title': {
    fontSize: '32px',
    fontWeight: 500,
    lineHeight: 1.3,
  },
  metadata: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 1.35,
  },
  'metadata-small': {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: 1.35,
  },
  'tab-active-text': {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: 1,
  },
  'tab-inactive-text': {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: 1,
  },
  'upsell-title': {
    fontSize: '27px',
    fontWeight: 500,
    lineHeight: 1.15,
  },
  'upsell-subtitle': {
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: 1.35,
  },
  'emphasized-title': {
    fontSize: '52px',
    fontWeight: 500,
    lineHeight: 1.2,
  },
  'byline-name': {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: 1.6,
    color: colors.red.tv4Light,
  },
};
