import type { ArticleFieldsFragment, ArticleInFeedFieldsFragment } from '@news/gql';
import type { ArticleGroupArticle } from '@news/lib';

import { AdGroupHeading, GroupHeading } from '../group-heading/GroupHeading';

interface Props {
  article: ArticleFieldsFragment | ArticleInFeedFieldsFragment | ArticleGroupArticle;
  feedSlug?: string;
}

export const ArticleGroupHeading = ({ article, feedSlug }: Props) => {
  if (article.isSponsoredArticle) {
    return <AdGroupHeading />;
  }
  if (
    !article.featuredFeed ||
    !article.featuredFeed.slug ||
    !article.featuredFeed.title ||
    article.featuredFeed.slug === feedSlug
  ) {
    return null;
  }
  return <GroupHeading feedSlug={article.featuredFeed.slug} label={article.featuredFeed.title} />;
};
