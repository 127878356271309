import type { PaginatedNewsFeedFieldsFragment } from '@news/gql';
import { Category } from '@news/gql';

export const getEmptyPaginatedFeed = (): PaginatedNewsFeedFieldsFragment => {
  return {
    id: '',
    emphasizedArticleGroup: {
      __typename: 'EmphasizedArticleGroup',
      content: [],
    },
    feed: {
      items: [],
      __typename: 'PaginatedFeed',
      pagingData: {
        __typename: 'PagingData',
        currentPage: 0,
        nextPage: 1,
      },
    },
    hero: null,
    sponsors: [],
    category: Category.News,
    slug: null,
    title: null,
    main16x9Annotated: null,
    metaDescription: null,
    metaTitle: null,
    modifiedAt: null,
    ogDescription: null,
    ogImage: null,
    ogTitle: null,
    publishedAt: null,
    redirect: null,
    __typename: 'PaginatedNewsFeed',
  };
};
