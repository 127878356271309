import Link from 'next/link';
import styled from 'styled-components';

import { sizeUnits } from '@news/design-tokens/src/sizeUnits';
import type { ArticleGroupArticle } from '@news/lib';
import { colors, getItemUrlForFeedItem } from '@news/lib';

import { Typography } from 'components/Typography';
import { Label } from 'components/labels';
import { toBrTags } from 'lib/toBrTags';

import { ArticleContentWrapper, Lead } from '../article/Article.styles';
import { ArticleGroupHeading } from '../article/ArticleGroupHeading';
import OpinionPiece from '../article/OpinionPiece';
import { FeaturedImage } from '../featured-image/FeaturedImage';

interface Props {
  article: ArticleGroupArticle;
  feedSlug?: string;
  isEmphasized?: boolean;
}

const ArticleContent: React.FC<Props> = ({ article, isEmphasized }) => {
  return (
    <ArticleContentWrapper>
      {article.liveReportId && <DirectReportLabel>Direktrapportering</DirectReportLabel>}
      <Typography variant={isEmphasized ? 'emphasized-title' : 'title1'} as="h2">
        {article.title}
      </Typography>
      {article.lead && (
        <Lead variant="title3-strong" $lineClamp={2}>
          {toBrTags(article.lead)}
        </Lead>
      )}
    </ArticleContentWrapper>
  );
};

export const ArticleGroupArticleLink = ({ article, feedSlug, isEmphasized = false }: Props) => {
  const link = getItemUrlForFeedItem(article);

  const content = article.isOpinionPiece ? (
    <OpinionPiece article={article} />
  ) : (
    <ArticleContent article={article} feedSlug={feedSlug} isEmphasized={isEmphasized} />
  );

  return (
    <ArticleWrapper $isEmphasized={isEmphasized}>
      <ArticleGroupHeading article={article} feedSlug={feedSlug} />
      <LinkContent href={link}>
        {article.featuredAsset?.__typename === 'Image' && <FeaturedImage image={article.featuredAsset.url || ''} />}
        {article.featuredAsset?.__typename === 'VideoPlayerData' && article.featuredAsset.asset?.image && (
          <FeaturedImage image={article.featuredAsset.thumbnail || article.featuredAsset.asset?.image} />
        )}
        {content}
      </LinkContent>
    </ArticleWrapper>
  );
};

const ArticleWrapper = styled.div<{
  $isEmphasized?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${({ $isEmphasized }) => ($isEmphasized ? colors.red.emphasized : colors.white.full)};
`;

const DirectReportLabel = styled(Label)`
  margin-bottom: ${sizeUnits[8]};
`;

const LinkContent = styled(Link)`
  color: inherit;
  width: 100%;

  &:hover {
    text-decoration: none;
  }

  &:hover ${Typography} {
    text-decoration: underline;
  }
`;
