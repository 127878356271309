import Link from 'next/link';
import styled from 'styled-components';

import { sizeUnits } from '@news/design-tokens/src/sizeUnits';
import type { ArticleFieldsFragment, ArticleInFeedFieldsFragment } from '@news/gql';
import { getItemUrlForFeedItem } from '@news/lib';

import { PowerOfBalance } from 'components/PowerOfBalance';
import { Typography } from 'components/Typography';
import { Label } from 'components/labels';
import { toBrTags } from 'lib/toBrTags';

import { ArticleContentWrapper, ArticleWrapper, Lead, LiveLabel } from './Article.styles';
import { ArticleFeaturedAsset } from './ArticleFeaturedAsset';
import { ArticleGroupHeading } from './ArticleGroupHeading';
import OpinionPiece from './OpinionPiece';
import { RelatedItemList } from './RelatedItemList';

interface Props {
  article: ArticleFieldsFragment | ArticleInFeedFieldsFragment;
  useSmallMargin?: boolean;
  feedSlug?: string;
}

const ArticleContent: React.FC<Props> = ({ article, feedSlug }) => {
  const isLiveArticle = article.live && feedSlug === '/';

  return (
    <ArticleContentWrapper>
      {article.liveReportId && <DirectReportLabel>Direktrapportering</DirectReportLabel>}
      <ArticleTitle variant={isLiveArticle ? 'live-title' : 'title1'} as="h2">
        {isLiveArticle && (
          <LiveLabel variant="live-label" as="span">
            Just&#8202;nu
          </LiveLabel>
        )}
        {article.title}
      </ArticleTitle>
      {article.lead && !isLiveArticle && (
        <Lead variant="title3-strong" $lineClamp={2}>
          {toBrTags(article.lead)}
        </Lead>
      )}
    </ArticleContentWrapper>
  );
};

export const ArticleLink = ({ article, feedSlug, useSmallMargin = false }: Props) => {
  const isLiveArticle = article.live && feedSlug === '/';

  const link = getItemUrlForFeedItem(article);

  const content = article.isOpinionPiece ? (
    <OpinionPiece article={article} />
  ) : (
    <ArticleContent article={article} feedSlug={feedSlug} />
  );

  return (
    <ArticleWrapper $isSponsored={article.isSponsoredArticle} $useSmallMargin={useSmallMargin}>
      <ArticleGroupHeading article={article} feedSlug={feedSlug} />
      <LinkContent href={link}>
        {!isLiveArticle && <ArticleFeaturedAsset article={article} hideText />}
        {content}
      </LinkContent>
      <RelatedItemList article={article} />
      {article?.showPobInFeed ? (
        <POBWrapper>
          <Lead variant="title3-strong">Fördelning av elektorsröster just nu</Lead>
          <PowerOfBalance />
        </POBWrapper>
      ) : null}
    </ArticleWrapper>
  );
};

const POBWrapper = styled.div`
  padding: ${sizeUnits[16]};
  padding-bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${sizeUnits[8]};
`;

const ArticleTitle = styled(Typography)`
  /* Only for CSS targeting */
`;

const DirectReportLabel = styled(Label)`
  margin-bottom: ${sizeUnits[8]};
`;

const LinkContent = styled(Link)`
  color: inherit;
  width: 100%;

  &:hover {
    text-decoration: none;
  }

  &:hover ${ArticleTitle} {
    text-decoration: underline;
  }
`;
