import type { ArticleFieldsFragment, BreakingNewsFieldsFragment, RelatedArticleFieldsFragment } from '@news/gql';

type Bylines = ArticleFieldsFragment['byline'];
type Byline = Bylines[0];

interface GetBylines {
  byline: Bylines;
  bylineName?: ArticleFieldsFragment['bylineName'];
  bylineEmail?: ArticleFieldsFragment['bylineEmail'];
}

export const getBylines = ({ byline, bylineName, bylineEmail }: GetBylines): Bylines => {
  if (byline?.length) {
    return byline;
  }

  return createBylines({ bylineEmail, bylineName });
};

/**
 * Helpers
 */

const createBylines = ({
  bylineName,
  bylineEmail,
}: {
  bylineName?: string | null;
  bylineEmail?: string | null;
}): Bylines => {
  if (!bylineName) {
    return [];
  }

  const names = getBylineArray(bylineName);
  const emails = getBylineArray(bylineEmail);

  if (names.length === 0) {
    return [];
  }

  if (names.length === emails.length) {
    return names.map((name, index) => createByline(name, emails[index]));
  }

  if (bylineName.includes('TT')) {
    return [createByline('TT'), ...createBylines({ bylineName: bylineName.replace('TT', ''), bylineEmail })];
  }

  if (names.length > emails.length) {
    return names.map((name, index) => createByline(name, emails[index]));
  }

  return emails.map((email, index) => createByline(names[index] ?? '', email));
};

const createByline = (name: string, email?: string): Byline => ({
  __typename: 'Byline',
  name,
  email: email ?? null,
  bylineImage: null,
});

const getBylineArray = (byline?: string | null) => {
  if (!byline) return [];
  return byline
    .split(',')
    .map((item) => item.trim())
    .filter(Boolean);
};

/**
 * @internal
 */
export const getBylineNames = ({
  byline,
  bylineName,
}: ArticleFieldsFragment | RelatedArticleFieldsFragment | BreakingNewsFieldsFragment) =>
  getBylines({ byline, bylineName })
    .map((byline) => byline.name)
    .join(', ');
