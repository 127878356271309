import Link from 'next/link';
import styled, { css } from 'styled-components';

import { sizeUnits } from '@news/design-tokens/src/sizeUnits';
import { ImagePlacement, Ratio } from '@news/gql';
import type { ArticleGroupBlockArticle } from '@news/lib';
import { colors, getItemUrlForFeedItem } from '@news/lib';

import { Typography } from 'components/Typography';
import { Label } from 'components/labels';
import { toBrTags } from 'lib/toBrTags';
import { ratioString } from 'styles/ratio';
import { theme } from 'styles/theme';

import { Lead } from '../article/Article.styles';
import { FeaturedImage } from '../featured-image/FeaturedImage';

type Size = 'regular' | 'large';

interface Props {
  article: ArticleGroupBlockArticle;
  imagePlacement: ImagePlacement | null;
  index?: number;
  isEmphasized?: boolean;
  size?: Size;
}

export const ArticleGroupBlockArticleLink = ({
  article,
  imagePlacement,
  isEmphasized = false,
  size = 'regular',
}: Props) => {
  if (!imagePlacement) return null;

  const link = getItemUrlForFeedItem(article);

  return (
    <ArticleWrapper $isEmphasized={isEmphasized}>
      <LinkContent href={link}>
        <StyledBlockLayout $imagePlacement={imagePlacement}>
          <ImageWrapper $imagePlacement={imagePlacement} $size={size}>
            {article.featuredAsset?.__typename === 'Image' && article.featuredAsset.url && (
              <FeaturedImage image={article.featuredAsset.url} />
            )}

            {article.featuredAsset?.__typename === 'VideoPlayerData' && article.featuredAsset.asset?.image && (
              <FeaturedImage image={article.featuredAsset.thumbnail || article.featuredAsset.asset?.image} />
            )}
          </ImageWrapper>
          <HeadingContainer $imagePlacement={imagePlacement}>
            {article.liveReportId && <DirectReportLabel>Direktrapportering</DirectReportLabel>}
            <Typography variant={size === 'regular' ? 'title3' : 'emphasized-title'} as="h1">
              {article.title}
            </Typography>
            {article.lead && size === 'large' && <Lead variant="title3-strong">{toBrTags(article.lead)}</Lead>}
          </HeadingContainer>
        </StyledBlockLayout>
      </LinkContent>
    </ArticleWrapper>
  );
};

const ArticleWrapper = styled.div<{ $isEmphasized: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  background-color: ${({ $isEmphasized }) => ($isEmphasized ? colors.red.emphasized : colors.white.full)};
`;

const LinkContent = styled(Link)`
  color: inherit;
  width: 100%;
  display: block;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &:hover h2 {
    text-decoration: underline;
  }
`;

const StyledBlockLayout = styled.div<{
  $imagePlacement: ImagePlacement;
}>`
  display: flex;
  align-items: start;
  width: 100%;
  flex-direction: ${({ $imagePlacement }) => ($imagePlacement === ImagePlacement.Top ? 'column' : 'row')};
  padding: ${({ $imagePlacement }) => ($imagePlacement === ImagePlacement.Top ? '0px' : '12px 0 12px 12px')};
`;

const leftStyles = css<{ $size: Size }>`
  width: 105px;
  aspect-ratio: ${ratioString(Ratio.Ratio_16x9)};
  background-color: ${colors.black[10]};
  border-radius: 4px;
  overflow: hidden;

  ${theme.mq.tablet} {
    width: ${({ $size }) => ($size === 'regular' ? '157px' : '376px')};
  }
`;

const topStyles = css`
  flex: 1;
`;

const ImageWrapper = styled.div<{ $imagePlacement: ImagePlacement; $size: Size }>`
  ${({ $imagePlacement }) => ($imagePlacement === ImagePlacement.Left ? leftStyles : topStyles)}
`;

const HeadingContainer = styled.div<{
  $imagePlacement: ImagePlacement;
}>`
  flex: 3;
  padding: ${({ $imagePlacement }) => ($imagePlacement === ImagePlacement.Top ? '8px 16px' : '0 8px 0 16px')};
`;

const DirectReportLabel = styled(Label)`
  margin-bottom: ${sizeUnits[8]};
`;
